<script setup lang="ts">
import { isEmpty } from 'lodash-es';
import { storeToRefs } from 'pinia';
import {
  ref,
  watch,
  computed,
  useIntersectionObserver,
  useHotelPopularDestination,
  until,
  useScroll,
  useNuxtApp,
} from '#imports';

const { country } = storeToRefs(useConfigStore());
const { locale } = useI18n();
const { getHotelSearchPath, getCityImage, trackPopularDestinationHotel, trackPopularDestinationHotelNav } =
  useHotelPopularDestination();

const isBot = useNuxtApp().$device.isCrawler;

const {
  data: hotels,
  pending,
  execute: fetchHotelPopularDestination,
} = await useAsyncData(
  () =>
    useHotelService().getHotelPopularDestination({
      country: country.value!,
      lang: locale.value,
    }),
  {
    immediate: isBot,
    transform: (data) => data.result.destinations,
  },
);

const hotelRecommendationRef = ref<HTMLElement | null>(null);
const currentIndex = ref(0);
const slideRefs = ref<(HTMLElement | null)[]>([]);
const initialState = ref(!isBot);
const scrollContainer = ref<HTMLElement | null>(null);

const { isScrolling } = useScroll(scrollContainer);

const disabledNextButton = computed(() => isEmpty(hotels.value) || currentIndex.value === hotels.value!.length - 1);

const disabledPrevButton = computed(() => isEmpty(hotels.value) || currentIndex.value === 0);

watch(hotels, (hotel) => {
  if (hotel) {
    slideRefs.value = Array.from({ length: hotel.length }, () => null);
  }
});

watch(isScrolling, (scrolling) => {
  if (scrolling) trackPopularDestinationHotelNav('slide');
});

function nextSlide() {
  if (hotels.value) {
    const slidesLength = hotels.value.length;
    let nextIndex;

    if (currentIndex.value + 1 === slidesLength) nextIndex = slidesLength - 1;
    else if (currentIndex.value + 1 > slidesLength) nextIndex = 0;
    else nextIndex = (currentIndex.value + (currentIndex.value === 0 ? 3 : 1)) % slidesLength;

    scrollToSlide(nextIndex, 'right');
  }
}

function previousSlide() {
  if (hotels.value) {
    const slidesLength = hotels.value.length;
    let prevIndex;

    if (currentIndex.value - 1 < 0) prevIndex = 0;
    else
      prevIndex =
        (currentIndex.value - (currentIndex.value === slidesLength - 1 ? 3 : 1) + slidesLength) % slidesLength;

    scrollToSlide(prevIndex, 'left');
  }
}

function scrollToSlide(index: number, direction: string) {
  const slide = slideRefs.value[index];

  if (slide) {
    const targetElement = slide instanceof HTMLElement ? slide : (slide as any)?.$el;

    if (targetElement && typeof targetElement.scrollIntoView === 'function') {
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      currentIndex.value = index;

      trackPopularDestinationHotelNav('nav', direction);
    }
  }
}

function setSlideRef(index: number, el: HTMLElement | null) {
  (slideRefs.value as (HTMLElement | null)[])[index] = el;
}

const { stop } = useIntersectionObserver(
  hotelRecommendationRef,
  // @ts-expect-error
  async ([{ isIntersecting }]) => {
    if (isIntersecting && !isBot) {
      fetchHotelPopularDestination();

      await until(pending).toBe(false);

      initialState.value = false;

      stop();
    }
  },
);
</script>

<template>
  <div
    ref="hotelRecommendationRef"
    class="w-full flex flex-col gap-y-15"
  >
    <div class="flex justify-between items-center px-15">
      <h2 class="text-gray-darkest font-bold">
        {{ $t('hotel.favoritedestination') }}
      </h2>
      <div class="flex justify-between w-[60px]">
        <ApzButton
          class="!p-0 !w-25 !h-25 flex justify-center items-center !rounded-full shadow-md"
          :disabled="disabledPrevButton"
          @click="previousSlide()"
        >
          <ApzIcon
            class="text-xsmall"
            :icon="['fas', 'chevron-left']"
          />
        </ApzButton>
        <ApzButton
          class="!p-0 !w-25 !h-25 flex justify-center items-center rounded-full shadow-md"
          :disabled="disabledNextButton"
          @click="nextSlide()"
        >
          <ApzIcon
            class="text-xsmall"
            :icon="['fas', 'chevron-right']"
          />
        </ApzButton>
      </div>
    </div>

    <div
      v-if="pending || initialState"
      class="w-full overflow-hidden"
    >
      <HotelPopularDestinationPlaceholderMobile />
    </div>

    <div
      v-else-if="!isEmpty(hotels)"
      ref="scrollContainer"
      class="overflow-x-scroll snap-x snap-mandatory scrollbar-hide w-full px-15"
    >
      <div class="flex gap-x-10">
        <NuxtLink
          v-for="(hotel, index) in hotels"
          :key="index"
          :ref="(el) => setSlideRef(index, (el as HTMLElement))"
          :to="getHotelSearchPath(hotel)"
          external
          no-rel
          class="flex-none w-[31%] snap-start scroll-mx-15"
          @click="trackPopularDestinationHotel(hotel)"
        >
          <div class="relative hover:cursor-pointer hover:shadow-md max-h-[365px]">
            <NuxtImg
              :src="getCityImage(hotel.nameEn)"
              :alt="hotel.name"
              format="webp"
              width="100"
              height="88"
              fit="cover"
              class="aspect-[100/88] w-full rounded object-cover"
              data-testid="hotel-recommended-card"
              :loading="index > 2 ? 'lazy' : 'eager'"
              sizes="sm:50vw"
              quality="50"
            />
          </div>
          <div class="flex flex-col items-start justify-start pt-10">
            <p class="text-small font-bold mb-3">
              {{ hotel.name }}
            </p>

            <p class="text-xsmall text-gray-dark">
              {{ $t('hotel.properties', { value: `${hotel.count}+` }) }}
            </p>
          </div>
        </NuxtLink>

        <div class="flex-none w-5" />
      </div>
    </div>
  </div>
</template>
